export const isEmpty = (list, field, errorList, index = undefined) => {
  if (list[field] === undefined || list[field] === "" || list[field] === " ") {
    var res = [...errorList];
    if (!errorList.includes(field)) {
      var res =
        index == undefined
          ? [...errorList, field]
          : [...errorList, index + field];
    }
    return res;
  }
  return errorList;
};

export const isEmptyOneField = (x, field, errorList, index = undefined) => {
  if (
    x === undefined ||
    x === "" ||
    x === " " ||
    x?.name == undefined ||
    x?.name == ""
  ) {
    var res = [...errorList];
    if (!errorList.includes(field)) {
      var res =
        index == undefined
          ? [...errorList, field]
          : [...errorList, index + field];
    }
    return res;
  }
  return errorList;
};

export const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

export const ValidateEmail = (email) => {
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  if (!pattern.test(email)) {
    return false;
  } else {
    return true;
  }
};

export const ValidPhone = (str) => {
  var isphone =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(str);
  return isphone;
};
export const numberWithCommas = (x) => {
  // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (x == undefined || x == null || x == 0 || x == "0.00") {
    return "0.00";
  } else {
    return Number(parseFloat(x).toFixed(2)).toLocaleString("en", {
      minimumFractionDigits: 2,
    });
  }
};
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export function isNumber(n) {
  return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
}
export const getInitData = () => {
  // const saved_user_info = localStorage.getItem("camper_user_info");
  // if (saved_user_info != null) {
  //   let tmpObj = JSON.parse(saved_user_info);
  //   let obj = {
  //     camperInfo:
  //       tmpObj.camperInfo == undefined
  //         ? [
  //           {
  //             first_name: "",
  //             last_name: "",
  //             age: "",
  //             grade: "",
  //             street: "",
  //             city: "",
  //             state: "",
  //             zip: "",
  //             t_size: "",
  //             health: "",
  //             sel_trip: "",
  //             src_fee: 0,
  //             real_fee: 0,
  //             sibling_discount: 0,
  //             zip_discount: 0,
  //             zip_include: false,
  //             sibling_include: false
  //           },
  //         ]
  //         : tmpObj.camperInfo,
  //     camper_reg_checked: tmpObj.camper_reg_checked,
  //     camper_reg_sel_info: tmpObj.camper_reg_sel_info,
  //     regList: tmpObj.regList == undefined ? [] : tmpObj.regList,
  //     waiverList: tmpObj.waiverList == undefined ? [] : tmpObj.waiverList,

  //     discount_amount: 0,

  //     p_first_name: tmpObj.p_first_name,
  //     p_last_name: tmpObj.p_last_name,
  //     p_cell_phone: tmpObj.p_cell_phone,
  //     p_home_phone: tmpObj.p_home_phone,
  //     p_email: tmpObj.p_email,
  //     p_relation: tmpObj.p_relation,
  //     a_first_name: tmpObj.a_first_name,
  //     a_last_name: tmpObj.a_last_name,
  //     a_cell_phone: tmpObj.a_cell_phone,
  //     a_home_phone: tmpObj.a_home_phone,
  //     a_email: tmpObj.a_email,
  //     a_relation: tmpObj.a_relation,
  //     c_first_name: tmpObj.c_first_name,
  //     c_last_name: tmpObj.c_last_name,
  //     c_email: tmpObj.c_email,
  //     c_cell_phone: tmpObj.c_cell_phone,
  //     a_park_id: tmpObj.a_park_id,
  //     a_adults: tmpObj.a_adults,
  //     a_spouse: tmpObj.a_spouse,
  //     a_spouse_anwser: tmpObj.a_spouse_anwser,
  //     a_c_first_name: tmpObj.a_c_first_name,
  //     a_c_last_name: tmpObj.a_c_last_name,
  //     a_c_email: tmpObj.a_c_email,
  //     a_c_cell_phone: tmpObj.a_c_cell_phone,

  //     bill_address: tmpObj.bill_address,
  //     bill_city: tmpObj.bill_city,
  //     bill_postal_code: tmpObj.bill_postal_code,
  //     bill_state: tmpObj.bill_state,
  //     tripList: tmpObj.tripList == undefined ? [] : tmpObj.tripList,
  //     disCountInfo: tmpObj.disCountInfo == undefined ? "" : tmpObj.disCountInfo,
  //     isPayment: tmpObj.isPayment == undefined ? "" : tmpObj.isPayment,
  //     payment_method_id:
  //       tmpObj.payment_method_id == undefined ? "" : tmpObj.payment_method_id,
  //     customer_id: tmpObj.customer_id == undefined ? "" : tmpObj.customer_id,
  //     payment_method:
  //       tmpObj.payment_method == undefined ? "" : tmpObj.payment_method,
  //     fee: tmpObj.fee,
  //     processing_card_fee: tmpObj.processing_card_fee,
  //     processing_bank_fee: tmpObj.processing_bank_fee,
  //     total_card: tmpObj.total_card,
  //     total_bank: tmpObj.total_bank,
  //     src_total_card: tmpObj.src_total_card,
  //     src_total_bank: tmpObj.src_total_bank,
  //   };
  //   return obj;
  // } else {
  //   return {
  //     camperInfo: [
  //       {
  //         first_name: "",
  //         last_name: "",
  //         age: "",
  //         grade: "",
  //         street: "",
  //         city: "",
  //         state: "",
  //         zip: "",
  //         t_size: "",
  //         health: "",
  //         sel_trip: "",
  //         src_fee: 0,
  //         real_fee: 0,
  //         sibling_discount: 0,
  //         zip_discount: 0,
  //         zip_include: false,
  //         sibling_include: false
  //       },
  //     ],
  //     camper_reg_checked: false,
  //     camper_reg_sel_info: {
  //       "ID": "",
  //       "registration_id": "",
  //       "registration_title": "",
  //       "registration_text": "",
  //       "registration_fee": "",
  //       "registration_sort_order": "",
  //       "sibling_discount": "",
  //       "sibling_discount_amount": "",
  //       "verify_zipcode": "",
  //       "zipcodes_allowed": "",
  //       "checked": false
  //     },
  //     isPayment: false,
  //     discount_amount: 0,
  //     w0_check: false,
  //     w1_check: false,
  //     w2_check: false,

  //     a_first_name: "",
  //     a_last_name: "",
  //     a_email: "",
  //     a_cell_phone: "",
  //     a_home_phone: "",
  //     a_relation: "",

  //     c_first_name: "",
  //     c_last_name: "",
  //     c_email: "",
  //     c_cell_phone: "",

  //     a_park_id: "",
  //     a_adults: "",
  //     a_spouse: "",
  //     a_spouse_anwser: "",
  //     a_c_first_name: "",
  //     a_c_last_name: "",
  //     a_c_email: "",
  //     a_c_cell_phone: "",
  //   };
  // }
};
