import { BackTop, message, Spin } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateUser } from "redux/actions/userActions";
import { apiGetRegistrationInfo } from "services/dashboardService";
import AppSection from "./AppSection/AppSection";
import ContactSection from "./ContactSection/ContactSection";
import Layout from "./Layout";
import MainSlide from "./MainSlide/MainSlide";
import { UpdateAppInfo } from "redux/actions/appActions";


const HomePage = () => {
  const dispatch = useDispatch();
  const [contactData, setContactData] = useState({});
  const [errorField, setErrorField] = useState([]);
  const [isBusy, setIsBusy] = useState(false);
  const [resStatus, setRecStatus] = useState(true);
  const [appInfo, setAppInfo] = useState({
    registration_info: null,
    discount_codes: [],
    group_list: [],
  });
  const [curDomain, setCurDomain] = useState("")

  useEffect(() => {
    let tmpDomain = window.location.hostname;
    if (tmpDomain.trim() == "localhost" || tmpDomain.trim() == "192.168.100.81") {
      tmpDomain = "devtuxedocamp.beclutch.club";
    }
    setCurDomain(tmpDomain)
    localStorage.removeItem("camper_user_info");
    localStorage.removeItem("camper_step");
    localStorage.removeItem("camper_registration_type")
    dispatch(updateUser({ token: undefined }));
    dispatch(UpdateAppInfo({ appInfo: undefined }));
    getData(tmpDomain);
  }, []);

  const getData = (tmpDomain) => {
    setIsBusy(true);
    apiGetRegistrationInfo(tmpDomain)
      .then((res) => {
        setIsBusy(false);
        if (res.result) {
          dispatch(UpdateAppInfo({ appInfo: res.data }));
          setAppInfo(res.data);
          if (res.data.registration_info?.status == "1") {
            setRecStatus(true);
          } else {
            setRecStatus(false);
          }
        }
      })
      .catch((err) => {
        setIsBusy(false);
        message.error("Something went wrong");
      });
  };

  return (
    <Layout resStatus={resStatus} appInfo={appInfo}>
      <Spin spinning={isBusy}>
        <MainSlide resStatus={resStatus} appInfo={appInfo} curDomain={curDomain} />
        <AppSection resStatus={resStatus} appInfo={appInfo} />
        <ContactSection
          contactData={contactData}
          setContactData={setContactData}
          resStatus={resStatus}
          appInfo={appInfo}
          errorField={errorField}
          setErrorField={setErrorField}
        />
      </Spin>
      <BackTop />
    </Layout>
  );
};

export default HomePage;
