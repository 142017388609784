import { Col, Input, Row, Select, InputNumber, message, DatePicker } from 'antd';
import React from 'react';
import { MaskedInput } from "antd-mask-input";
import { capitalizeFirstLetter, isNumber } from 'utils/GlobalFunctions';
import moment from "moment";
const { Option } = Select;
const dateFormat = "MM/DD/YYYY";
const UiForm = props => {
    const { index, element, recordList, setRecordList, errorField, setErrorField, userData, setUserData, data } = props;

    const onDropImage = (e, ii) => {
        if (errorField.includes(ii + "record")) {
            let errors = errorField.filter((y) => y != ii + "record");
            setErrorField([...errors]);
        }
        const files = Array.from(e.target.files);
        if (files.length === 0) return;
        if (files[0].size / 1000 / 1000 > 5) {
            message.error("File size should not be greater than 5Mb.");
            return;
        }
        let tmpCampList = [...recordList];
        tmpCampList[ii] = files[0];
        setRecordList(tmpCampList);
    };

    const getUiElement = () => {
        if (element.ui_type === "1") { //Text
            return <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>
                    {element.name}
                    {element.is_required == "true" ? "*" : ""}
                </div>
                <Input
                    value={data[element.reg_column_name]}
                    placeholder={element.hint_text}
                    className={
                        errorField !== undefined &&
                            errorField.includes(index + `${element.reg_column_name}`)
                            ? `common-input-error`
                            : `common-input`
                    }
                    onChange={(e) => {
                        if (element.is_required == "true" && errorField.includes(index + `${element.reg_column_name}`)) {
                            let errors = errorField.filter(
                                (y) => y != index + `${element.reg_column_name}`
                            );
                            setErrorField([...errors]);
                        }
                        let tmpCampList = [...userData.camperInfo];
                        tmpCampList[index][element.reg_column_name] = element.reg_column_name.includes("name") ? capitalizeFirstLetter(
                            e.target.value
                        ) : e.target.value;

                        if (element.reg_column_name == "camper_street") {
                            setUserData({
                                ...userData,
                                bill_address: e.target.value,
                                camperInfo: tmpCampList
                            });
                        } else {
                            setUserData({
                                ...userData,
                                camperInfo: tmpCampList,
                            });
                        }
                        if (element.reg_column_name == "camper_city") {
                            setUserData({
                                ...userData,
                                bill_city: e.target.value,
                                camperInfo: tmpCampList,
                            });
                        } else {
                            setUserData({
                                ...userData,
                                camperInfo: tmpCampList,
                            });
                        }
                        if (element.reg_column_name == "camper_zip") {
                            setUserData({
                                ...userData,
                                bill_postal_code: e.target.value,
                                camperInfo: tmpCampList,
                            });
                        } else {
                            setUserData({
                                ...userData,
                                camperInfo: tmpCampList,
                            });
                        }
                    }}
                />
            </Col>
        } else if (element.ui_type === "2") { //Number
            return <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>
                    {element.name}
                    {element.is_required == "true" ? "*" : ""}
                </div>
                <InputNumber
                    min={1}
                    max={100}
                    size="small"
                    placeholder={element.hint_text}
                    value={data[element.reg_column_name]}
                    className={
                        errorField !== undefined &&
                            errorField.includes(index + `${element.reg_column_name}`)
                            ? `common-input-error`
                            : `common-input`
                    }
                    onChange={(e) => {
                        if (element.is_required == "true" && errorField.includes(index + `${element.reg_column_name}`)) {
                            let errors = errorField.filter(
                                (y) => y != index + `${element.reg_column_name}`
                            );
                            setErrorField([...errors]);
                        }
                        let tmpCampList = [...userData.camperInfo];
                        tmpCampList[index][element.reg_column_name] = e;
                        setUserData({
                            ...userData,
                            camperInfo: tmpCampList,
                        });
                    }}
                    step={1}
                />
            </Col>
        } else if (element.ui_type === "3") { //Dropdown
            return <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>
                    {element.name}
                    {element.is_required == "true" ? "*" : ""}
                </div>
                <Select
                    value={data[element.reg_column_name] == undefined ? null : data[element.reg_column_name]}
                    placeholder={element.hint_text}
                    className={
                        errorField !== undefined &&
                            errorField.includes(index + `${element.reg_column_name}`)
                            ? `common-select-error`
                            : `common-select`
                    }

                    onSelect={(v) => {
                        if (element.is_required == "true" && errorField.includes(index + `${element.reg_column_name}`)) {
                            let errors = errorField.filter(
                                (y) => y != index + `${element.reg_column_name}`
                            );
                            setErrorField([...errors]);
                        }
                        let tmpCampList = [...userData.camperInfo];
                        tmpCampList[index][element.reg_column_name] = v;

                        if (element.reg_column_name == "camper_state") {
                            setUserData({
                                ...userData,
                                bill_postal_code: v,
                                camperInfo: tmpCampList,
                            });
                        } else {
                            setUserData({
                                ...userData,
                                camperInfo: tmpCampList,
                            });
                        }
                    }}
                >
                    {
                        element.dropdown_list && element.dropdown_list.split(",").map((dropItem, dropIndex) => {
                            return <Option value={dropItem} key={dropIndex}>{dropItem}</Option>
                        })
                    }
                </Select>
            </Col>
        } else if (element.ui_type === "4") { //Phone Number
            return <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>
                    {element.name}
                    {element.is_required == "true" ? "*" : ""}
                </div>
                <Input
                    type="text"
                    placeholder="___-___-____"
                    value={data[element.reg_column_name]}
                    className={
                        errorField !== undefined &&
                            errorField.includes(index + `${element.reg_column_name}`)
                            ? `common-input-error`
                            : `common-input`
                    }
                    onChange={(e, w) => {

                        let str = e.target.value;
                        let filStr = str.replace(/[- ]/g, "");
                        let tmpPhone = "";
                        if (filStr == "" || filStr.length == 0) {
                            let tmp0CampList = [...userData.camperInfo];
                            tmp0CampList[index][element.reg_column_name] = "";
                            setUserData({
                                ...userData,
                                camperInfo: tmp0CampList,
                            });
                            return;
                        }
                        if (!isNumber(filStr)) {
                            return;
                        }
                        if (filStr.length > 10) {
                            return;
                        }

                        if (element.is_required == "true" && errorField.includes(index + `${element.reg_column_name}`)) {
                            let errors = errorField.filter(
                                (y) => y != index + `${element.reg_column_name}`
                            );
                            setErrorField([...errors]);
                        }


                        if (filStr.length > 3 && filStr.length < 7) {
                            str = filStr.slice(0, 3) + "-" + filStr.slice(3);
                        }

                        if (filStr.length > 6) {
                            str =
                                filStr.slice(0, 3) +
                                "-" +
                                filStr.slice(3, 6) +
                                "-" +
                                filStr.slice(6);
                        }
                        let tmpCampList = [...userData.camperInfo];
                        tmpCampList[index][element.reg_column_name] = str;
                        setUserData({
                            ...userData,
                            camperInfo: tmpCampList,
                        });
                    }}
                />
                {/* <MaskedInput
                    mask={"000-000-0000"}
                    value={data[element.reg_column_name]}
                    className={
                        errorField !== undefined &&
                            errorField.includes(index + `${element.reg_column_name}`)
                            ? `common-input-error`
                            : `common-input`
                    }
                    onChange={(e) => {
                        if (element.is_required == "true" && errorField.includes(index + `${element.reg_column_name}`)) {
                            let errors = errorField.filter(
                                (y) => y != index + `${element.reg_column_name}`
                            );
                            setErrorField([...errors]);
                        }
                        let tmpCampList = [...userData.camperInfo];
                        tmpCampList[index][element.reg_column_name] = e;
                        setUserData({
                            ...userData,
                            camperInfo: tmpCampList,
                        });
                    }}
                /> */}
            </Col>
        } else if (element.ui_type === "5") { //Button
            if (element.is_filepicker === "true") {
                return <>
                    <div
                        style={{
                            marginTop: 15,
                            color: "var(--redColor)",
                        }}
                    >
                        PLEASE NOTE: IMMUNIZATION RECORDS MUST BE UPLOADED IN
                        ORDER TO PROCEED WITH THIS REGISTRATION
                    </div>
                    <div style={{ marginTop: 5, marginBottom: 15, width: '100%' }}>
                        Click the Immunization Record button below to upload
                        (files must be less than 5MB in size)
                    </div>
                    <div>
                        <Row gutter={[4, 8]}>
                            <Col>
                                <label htmlFor={"new_image_uploader" + index}>
                                    <input
                                        type="file"
                                        id={"new_image_uploader" + index}
                                        name={"new_image_uploader" + index}
                                        style={{ display: "none" }}
                                        accept="*"
                                        onChange={(e) => {
                                            onDropImage(e, index);
                                        }}
                                    />
                                    <span className="custom-button common-button ">
                                        Immunization Record*
                                    </span>
                                </label>
                            </Col>
                            <Col>
                                {recordList[index]?.name && (
                                    <span style={{ paddingLeft: 20 }}>
                                        {recordList[index]?.name}
                                    </span>
                                )}
                            </Col>
                        </Row>
                    </div>
                    {errorField !== undefined &&
                        errorField.includes(index + "record") && (
                            <div style={{ marginTop: 10 }}>
                                <span style={{ color: "var(--redColor)" }}>
                                    You must upload the campers immunization record in
                                    order to proceed
                                </span>
                            </div>
                        )}
                </>
            }
        } else if (element.ui_type === "6") { //Date
            return <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>
                    {element.name}
                    {element.is_required == "true" ? "*" : ""}
                </div>
                <DatePicker
                    style={{
                        width: "100%",
                        cursor: "pointer",
                    }}
                    format={dateFormat}
                    allowClear={false}
                    value={
                        data[element.reg_column_name] ? moment(data[element.reg_column_name], dateFormat) : ""
                    }
                    className={
                        errorField !== undefined &&
                            errorField.includes(index + `${element.reg_column_name}`)
                            ? `common-input-error`
                            : `common-input`
                    }
                    onChange={(v, str) => {
                        if (element.is_required == "true" && errorField.includes(index + `${element.reg_column_name}`)) {
                            let errors = errorField.filter(
                                (y) => y != index + `${element.reg_column_name}`
                            );
                            setErrorField([...errors]);
                        }
                        let tmpCampList = [...userData.camperInfo];
                        tmpCampList[index][element.reg_column_name] = str;
                        setUserData({
                            ...userData,
                            camperInfo: tmpCampList,
                        });
                    }}
                />
            </Col>
        }
    }
    return (
        <>
            {getUiElement()}
        </>
    );
};

export default UiForm;
